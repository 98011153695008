@media (max-width:1200px) {
    .registered-vendor-close {
        position: relative;
        bottom: 0;
        right: 0;
    }
}

@media (max-width:1199px) {
    .description {
        margin-bottom: 10px;
    }
}

@media (min-width:1700px) {
    td.sales-alignment {
        padding-right: 90px;
    }
}

@media (min-width:1800px) {
    td.sales-alignment {
        padding-right: 110px;
    }
}
@media (min-width:1500px) {
    td.miscellaneous-refund {
        padding-right: 240px;
    }
}
@media (min-width:1700px) {
    td.miscellaneous-refund {
        padding-right: 300px;
    }
}

@media (min-width:1800px) {
    td.miscellaneous-refund {
        padding-right: 350px;
    }
}

@media (min-width:1900px) {
    td.miscellaneous-refund {
        padding-right: 490px;
    }
}

@media (min-width:1500px) {
    td.vendor-sales-alignment {
        padding-right: 55px;
    }
}

@media (min-width:1700px) {
    td.vendor-sales-alignment {
        /* padding-right: 145px; */
        padding-right: 75px;
    }
}

@media (min-width:1800px) {
    td.vendor-sales-alignment {
        /* padding-right: 165px; */
        padding-right: 85px;
    }
}

@media (min-width:1700px) {
    tr.price-alignment td:last-child {
        padding-right: 140px;
    }
}

@media (min-width:1800px) {
    tr.price-alignment td:last-child {
        padding-right: 170px;
    }
}

@media (min-width:1500px) {
    td.hamper-price-alignment {
        padding-right: 110px;
    }
}

@media (min-width:1700px) {
    td.hamper-price-alignment {
        padding-right: 175px;
    }
}

@media (min-width:1800px) {
    td.hamper-price-alignment {
        padding-right: 220px;
    }
}

@media (min-width:1500px) {
    td.vendor-hamper-price-alignment {
        padding-right: 170px;
    }
}

@media (min-width:1700px) {
    td.vendor-hamper-price-alignment {
        padding-right: 250px;
    }
}

@media (min-width:1800px) {
    td.vendor-hamper-price-alignment {
        padding-right: 305px;
    }
}

@media (min-width:1500px) {
    td.vendor-topper-price-alignment {
        padding-right: 240px;
    }
}

@media (min-width:1700px) {
    td.vendor-topper-price-alignment {
        padding-right: 325px;
    }
}

@media (min-width:1800px) {
    td.vendor-topper-price-alignment {
        padding-right: 375px;
    }
}

@media (min-width:1700px) {
    td.vendor-statement-alignment {
        padding-right: 145px;
    }
}

@media (min-width:1800px) {
    td.vendor-statement-alignment {
        padding-right: 165px;
    }
}

@media (min-width:1500px) {
    td.vendor-report-share-of-cakey-alignment {
        padding-right: 145px;
    }
}

@media (min-width:1700px) {
    td.vendor-report-share-of-cakey-alignment {
        padding-right: 145px;
    }
}

@media (min-width:1800px) {
    td.vendor-report-share-of-cakey-alignment {
        padding-right: 165px;
    }
}

@media (min-width:1500px) {
    td.vendor-report-statement-alignment {
        padding-right: 95px;
    }
}

@media (min-width:1700px) {
    td.vendor-report-statement-alignment {
        padding-right: 125px;
    }
}

@media (min-width:1800px) {
    td.vendor-report-statement-alignment {
        padding-right: 145px;
    }
}

@media (min-width:1500px) {
    td.vendor-payment-price-alignment {
        padding-right: 225px;
    }
}

@media (min-width:1700px) {
    td.vendor-payment-price-alignment {
        padding-right: 310px;
    }
}

@media (min-width:1800px) {
    td.vendor-payment-price-alignment {
        padding-right: 365px;
    }
}

@media (min-width:1500px) {
    td.accounts-payment-alignment {
        padding-right: 110px;
    }
}

@media (min-width:1700px) {
    td.accounts-payment-alignment {
        padding-right: 150px;
    }
}

@media (min-width:1800px) {
    td.accounts-payment-alignment {
        padding-right: 200px;
    }
}

/* @media (min-width:1500px) {
    td.accounts-refund-report-price {
        padding-right: 34px;
    }
}

@media (min-width:1700px) {
    td.accounts-refund-report-price {
        padding-right: 34px;
    }
}

@media (min-width:1800px) {
    td.accounts-refund-report-price {
        padding-right: 34px;
    }
} */

@media (max-width:767px) {
    .dashboard-top {
        margin-bottom: 10px;
    }
}

@media (max-width: 1160px) and (min-width:1024px){
    .vendor-cakes-images{
        height: 50%;
        width: 50%;
    }
}

@media (max-width:991px) {
    .vendor-report-select {
        margin-bottom: 10px;
    }
}

/* @media (min-width:1700px) {
    tr.sales-alignment td:nth-child(5) {
        padding-right: 90px;
    }
} 
@media (min-width:1800px) {
    tr.sales-alignment td:nth-child(5) {
        padding-right: 110px;
    }
}  */
/* @media (min-width:1700px) {
    tr.sales-alignment td:nth-child(6) {
        padding-right: 90px;
    }
} 
@media (min-width:1800px) {
    tr.sales-alignment td:nth-child(6) {
        padding-right: 110px;
    }
}  */

/* @media (min-width:1700px) {
    tr.sales-alignment td:last-child {
        padding-right: 90px;
    }
} 
@media (min-width:1800px) {
    tr.sales-alignment td:last-child {
        padding-right: 110px;
    }
}  */

/* @media (max-width:991px) {
    .container{
        display: block;
        margin: 20px;
        padding-right: 0px
    }
    .registered-vendor-close {
        margin-right: 20px;
    }
}    */