button.deleteIcon {
    color: #ff6877;
    border: none;
    background-color: transparent;
}

.id-column {
    text-align: center;
}

.EditButton {
    background-color: #369cad;
    border-color: #369cad;
}

.EditButton:hover {
    background-color: #125561;
    border-color: #125561;
}

.profile-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 45px;
    width: 45px;
}

.dropdown-header-name {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 15px;
    color: #6c757d;
    white-space: nowrap;
}

input::-webkit-input-placeholder {
    font-size: 13px;
}

.recentorderSearch {
    margin-top: -25px;
    margin-left: 240px;
}

select.search-select-option {
    padding: 5px;
    border-radius: 20px;
    border-color: #dfd4d4;
    margin-right: 15px;
    width: 8rem;
}

.date-picker {
    border-radius: 20px;
    padding: 3px;
    border-color: #dfd4d4;
    font-size: 14px;
}

.carousel-item img {
    height: 40%;
    width: 40%;
    left: 0;
    right: 0;
    object-fit: contain;
    margin: auto;
}

.cake-details-button {
    margin-top: -15px;
}

/* .login-spinner {
    position: fixed;
    margin-left: 150px;
} */

.login-spinner {
    /* position: fixed; */
    margin-left: 40%;
    padding-top: 20px;
    padding-bottom: 15px;
}

.notification-dropdown {
    padding: 0 15px 15px 15px;
}

.cakey-logo-backround {
    height: 100px;
}

img.cakey-logo {
    height: 65px;
}

.login-title {
    font-family: ui-sans-serif;
}

.cursor {
    cursor: pointer !important;
}

.order-image img {
    height: 40%;
    width: 40%;
    left: 0;
    right: 0;
    object-fit: contain;
    margin: auto;
}

img.login-logo {
    /* margin-left: 110px; */
    height: 60px;
}

.cake-carousel-item {
    padding: 20px;
    margin: 10px;
    border: 2px solid #a99f9fa6;
    border-radius: 10px;
    position: relative;
}

.cake-carousel-item h4 {
    text-align: center;
    margin-top: 20px;
    color: #ff6877;
}

.cake-carousel-item p {
    margin-top: 10px;
    margin-left: 10px;
}

.cake-carousel-item a {
    color: blue;
    margin-top: 10px;
    margin-left: 10px;
}

.cake-carousel-item a:hover {
    color: #ff6877;
}

.cake-carousel-item img {
    border-radius: 10px;
}

img.register-logo {
    margin-left: 46%;
    margin-bottom: 20px;
}

.register-header {
    margin-left: 25%;
}

.registered-vendor-close {
    /* position: absolute;
    bottom: -150px;
    right: 35px;  */
    justify-content: flex-end;
    display: flex;
}

.container {
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    display: flex;
}

.description {
    margin-bottom: 25%;
}

th.active {
    color: #ff6877;
    /* background-color:#dbdbdb ; */
    cursor: pointer;
}

.hover :hover {
    color: #ff6877;
    cursor: pointer;
}

.login-spinner2 {
    position: relative;
    margin-left: 200px;
}

button.category-row-btn {
    position: absolute;
    right: 0;
    /* left: 0;  */
    top: 0;
    cursor: pointer;
    margin-top: 8px;
    /* margin-top: 13px; */
    color: white;
}

.category-row {
    position: relative;
    width: 100%;
}

i.fas.fa-pen {
    font-size: 12px;
    margin: 0 -3px;
}

.remove-2 {
    font-size: 20px;
    font-size: 20px;
    border-radius: 50%;
    height: 29px;
    width: 29px;
    text-align: center;
    /* align-content: center;
    padding-left: 5px;
    padding-right: 5px; */
}

.edit-button {
    background-color: #469959;
    border-color: #469959;
    border-style: solid;
}

.scrollable-list {
    height: 120px;
    overflow-y: auto;
    border: 1px solid rgb(218, 213, 213);
    border-radius: 5px;
}

.scrollable-list::-webkit-scrollbar {
    width: 7px;
}

.scrollable-list::-webkit-scrollbar-thumb {
    background: #868383;
    border-radius: 10px;
}

.Scrollbar-Style::-webkit-scrollbar {
    height: 10px;
}

.Scrollbar-Style::-webkit-scrollbar-thumb {
    background: #868383;
    border-radius: 10px;
}

.list-main {
    margin: 10px;
}

.add-button {
    background-color: #2d703e;
    color: #f8f9fa;
    border-color: #2d703e;
    border-radius: 50%;
    margin-right: 3px;
    border-style: solid;
    font-size: 14px;
}

.minus-button {
    background-color: #ff6877;
    color: #f8f9fa;
    border-color: #ff6877;
    border-radius: 50%;
    border-style: solid;
    font-size: 13px;
    font-weight: bold;
    vertical-align: middle;
}

.price-input {
    max-width: 60px;
    border-radius: 5px;
    border: 1px solid rgb(187, 181, 181);
}

i.price-info {
    font-size: 13px;
    margin-right: 5px;
}

.y {
    background-color: #445cc8;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.n {
    background-color: #ff6877;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.dropdown-toggle::after {
    content: none;
}

.style-badge {
    color: transparent;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-bottom: 23px;
}

span.Notification {
    margin-left: -10px;
}

span.Chat-Notification {
    margin-left: -9px;
}

.sidenav-background {
    /* background-image: url("https://res.cloudinary.com/mindmadetech/image/upload/v1661494039/CakeyLogo/image_2022_08_26T06_01_19_871Z_nz8tcp.png"); */
    background-image: url("https://sugitechnologies.com/images/AppImages/image_2022_08_26T06_01_19_871Z_nz8tcp.png");
    background-size: 'cover';
}

.tabledata-background {
    /* background-image: url("https://res.cloudinary.com/mindmadetech/image/upload/v1661494039/CakeyLogo/image_2022_08_26T06_01_19_871Z_nz8tcp.png"); */
    background-image: url("https://sugitechnologies.com/images/AppImages/image_2022_08_26T06_01_19_871Z_nz8tcp.png");
    background-size: 'cover';
}

button.edit-profile {
    border: 2px solid #f0f0fa;
    border-radius: 5px;
}

.ASSIGNED {
    background-color: #ff6877;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.disabled-assign:disabled {
    color: black;
    font-weight: bold;
}

.extracharge-table {
    text-align: center;
    border: 1px solid #dee2e6;
    margin-left: 25px;
}

.SENT {
    background-color: #28a745;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.unseen {
    background-color: #445cc8;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.ORDERED {
    background-color: #f531b0;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.modal-body-content {
    padding: 50px 35px 35px 35px;
}

.change-password-close {
    margin-top: -30px;
}

.eye-icon {
    font-size: 20px !important;
}

.eye-button {
    background-color: transparent;
    border: 1px solid #dee4e4;
    border-radius: 0 3px 3px 0;
}

.email-icon {
    background-color: transparent;
    border: 1px solid #e5e2e1;
    border-radius: 3px 0 0 3px;
}

.remove-buttons {
    background-color: #ff6877;
    color: #f8f9fa;
    border-color: #ff6877;
    border-radius: 50%;
    margin-right: 3px;
    border-style: solid;
    font-size: 14px;
}

button.cake-remove {
    border-style: solid;
    font-size: 16px;
}

.required-field {
    border-radius: 2px;
    box-shadow: 0 0 0 0.2rem rgb(241 75 75 / 25%);
    border: 1px #ff00007a solid !important;
}

.required-field:focus {
    border-radius: 2px;
    box-shadow: 0 0 0 0.2rem rgb(241 75 75 / 25%);
    border: 1px #ff00007a solid !important;
}

.list-map {
    margin-top: 15px;
    border: 1px solid #a5a0a0;
    margin-right: 15px;
    padding: 5px;
    border-radius: 10px;
}

.list-map-remove {
    border-radius: 10px;
    font-size: 12px;
    background: #302d2d;
    border: #302d2d;
}

.disabled-add:disabled {
    background-color: grey;
    border-color: grey;
    cursor: not-allowed;
}

.list-array {
    margin: 10px;
    border: 1px solid #dad5d5;
    padding: 4px;
    width: 150px;
    border-radius: 10px;
    text-align: center;
    background-color: #dad5d5;
    font-weight: 600;
}

.Approved {
    background-color: #ff6877;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.Expert {
    background-color: #ff6877;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.Regular {
    background-color: #28a745;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.Premium {
    background-color: #9403fc;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.Rare {
    background-color: #fc036b;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.Special {
    background-color: #e89800;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}


.Beginner {
    background-color: #fc036b;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.Good {
    background-color: #28a745;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.Specialist {
    background-color: #9403fc;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.Excellent {
    background-color: #445cc8;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

p.top-deopdown-letter {
    /* border: 2px solid #ff6877; */
    border-radius: 50%;
    background-color: #ff6877;
    font-size: 16px;
    color: white;
    text-align: center;
    /* margin-bottom: 4px; */
    height: 24px;
    width: 24px;
    margin: 0px 0;
    line-height: 25px;
}

.top-letter-border {
    border: 2px solid #ff6877;
    border-radius: 50%;
    padding: 2px;
}

.rectangle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    width: 50px;
    height: 50px;
    background-color: #1a9637;
    transform: scale(0);
    border-radius: 50%;
    color: white;
    opacity: 0;
    font-weight: 700;
    overflow: hidden;
    animation: scale-in 0.3s ease-out forwards, expand 0.35s 0.25s ease-out forwards;
    z-index: 999999999;
    bottom: 2rem;
    left: 1rem;
    margin: auto;
}

.notification-text {
    display: flex;
    align-items: center;
    padding: 0 16px;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    animation: fade-in 0.65s ease-in forwards;
}

.bg-red {
    background-color: red;
}

@keyframes scale-in {
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes expand {
    50% {
        width: 350px;
        border-radius: 6px;
    }

    100% {
        width: 300px;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -1px rgba(0, 0, 0, 0.12);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.8;
    }
}

.upload-button {
    margin-left: 80%;
    background-color: #2323fce3;
    border: none;
}

.upload-button:hover {
    margin-left: 80%;
    background-color: #2e2e91e3;
    border: none;
}

.date-selector {
    margin-top: -25px;
    margin-left: 200px;
}

.add-banner-button {
    margin-top: -55px;
}

.edit-banner {
    background-color: #f9423c;
    border: 2px solid #f9423c;
    font-size: 18px;
    color: white;
    border-radius: 50%;
}

.edit-banner:hover {
    background-color: #881f1c;
    border: 2px solid #881f1c;
    font-size: 18px;
    color: white;
    border-radius: 50%;
}

.delete-banner {
    background-color: black;
    border: 2px solid black;
    font-size: 18px;
    color: white;
    border-radius: 50%;
}

.delete-banner:hover {
    background-color: rgb(58, 56, 56);
    border: 2px solid rgb(58, 56, 56);
    font-size: 18px;
    color: white;
    border-radius: 50%;
}

.banner-image {
    height: 25vh;
    width: 100%;
    left: 0;
    right: 0;
    object-fit: contain;
    margin: auto;
}

.vendor-select-option {
    max-width: 100%;
    box-sizing: border-box;
    white-space: normal;
    word-wrap: break-word;
}

.header-break-line {
    margin-top: -15px;
    margin-bottom: -15px;
}

.new-disabled {
    cursor: not-allowed;
}

.UPDATED {
    background-color: #e89800;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: none;
}

.mark-as-read {
    float: right;
    color: grey;
    font-weight: bold;
    cursor: pointer;
    text-decoration-line: underline;
}

.notification-time {
    font-size: 12px;
    color: gray;
}

span.READY {
    background-color: rgb(43, 230, 83);
    color: white;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
}

span.ACCEPTED {
    background-color: rgb(14, 168, 179);
    color: white;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
}

span.OUTFORDELIVERY {
    background-color: rgb(140, 23, 218);
    color: white;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
}

.APPROVED {
    background-color: green;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.NOTAPPROVED {
    background-color: #ff6877;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.SUSPENDED {
    background-color: #ff6877;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.price-input-others {
    max-width: 90px;
    border-radius: 5px;
    border: 1px solid rgb(187, 181, 181);
    /* margin-right: 35px; */
}

.add-weight-hover:hover {
    background-color: rgb(187, 181, 181);
}

.long-line-break {
    white-space: pre-wrap;
}

.fa-select option {
    font-family: 'Lato', 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
    font-weight: 700;
}

.fa-select {
    font-family: 'Lato', 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
    font-weight: 700;
}

.schedule-tab:hover {
    background-color: #ff6877 !important;
    color: white !important;
}

.schedule-tab {
    font-size: 16px;
    color: black;
    flex: none !important;
}

.tab-style {
    border-bottom: 1px solid rgb(221, 220, 220);
}

.schedule-tab.active {
    background-color: #ff6877 !important;
    color: white !important;
    border-radius: 10px 10px 0 0 !important;
}

.filter-button {
    margin-left: 3px;
    border-radius: 4px;
    font-weight: bold;
    border: #07be6e;
    padding: 3px 10px;
}

.tab-pane-content {
    margin-top: -15px !important;
}

.REJECTED {
    background-color: #e89800;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.notification-info {
    border: 1px solid rgb(214, 211, 211);
    border-radius: 5px;
}

.caketypeimage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid rgb(214, 211, 211);
    padding: 10px;
    border-radius: 5px;
}

.toastBody {
    min-height: 10px !important;
}

.filter-button:disabled {
    cursor: not-allowed !important;
    background-color: gray;
}

.cursor-notallowed {
    cursor: not-allowed;
}

/* .select-required-field{
    box-shadow: 0 0 0 0.2rem rgb(241 75 75 / 25%);
    border: 1px #ff00007a solid !important;
    border-radius: 2px;
} */
.chat-content {
    display: flex;
}

.chat-content-right {
    position: relative;
}

/* .chat-content-right-bottom {
    position: absolute;
    bottom: 10px;
} */

a.cursor-pointer {
    cursor: pointer;
}

.ms-chat-conversations .ms-panel-body {
    height: 80vh !important;
}

.INPROGRESS {
    background-color: green;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.sidenavigationIcon {
    font-size: 20px;
}

.home {
    font-size: 16px;
    margin-bottom: 3px;
}

.status-radio-button {
    margin-left: 35%;
    font-size: 16px;
}

.typing-indicator {
    /* background-color: #E6E7ED; */
    will-change: transform;
    width: auto;
    border-radius: 50px;
    padding: 5px;
    display: table;
    position: relative;
    -webkit-animation: 2s bulge infinite ease-out;
    animation: 2s bulge infinite ease-out;
}


.typing-indicator span {
    height: 7px;
    width: 7px;
    float: left;
    margin: 0 1px;
    background-color: #9E9EA1;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
    -webkit-animation: 1s blink infinite 0.3333s;
    animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(2) {
    -webkit-animation: 1s blink infinite 0.6666s;
    animation: 1s blink infinite 0.6666s;
}

.typing-indicator span:nth-of-type(3) {
    -webkit-animation: 1s blink infinite 0.9999s;
    animation: 1s blink infinite 0.9999s;
}

@-webkit-keyframes blink {
    50% {
        opacity: 1;
    }
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}

@-webkit-keyframes bulge {
    50% {
        transform: scale(1.05);
    }
}

@keyframes bulge {
    50% {
        transform: scale(1.05);
    }
}

/* html {
    display: table;
    height: 100%;
    width: 100%;
}

body {
    display: table-cell;
    vertical-align: middle;
} */

.chat-message-Send-button {
    float: right;
    color: #ff6877;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    font-weight: bold;
    background: #ffffff;
}

.load-more-message button {
    color: #ff6877;
    border-radius: 3px;
    font-size: 10px;
    border: 1px solid #f0f0f7;
    text-transform: uppercase;
    padding: 1px 8px;
    background-color: #f0f0f7;
}

.load-more-message {
    text-align: center;
    padding: 5px 0px 15px 0px;
}

.load-more-message button:hover {
    background: white;
    color: #ff6877;
}

.ms-widget.ms-chat-conversations .ms-panel-footer {
    padding: 15px !important;
}

.ms-chat-textbox {
    margin: 10px;
    padding: 10px !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 25px;
}

.loader {
    width: 30px;
    height: 30px;
    border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 3px solid;
    border-color: #FF3D00 transparent;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.refresh-styles {
    color: black;
    background-color: #80808038;
    padding: 2px 7px 0px 7px;
    border: 1px solid #8080805e;
    border-radius: 5px;
    margin-top: 0px;
}

.refresh-styles:hover {
    background-color: #8080805e;
    border: 1px solid #80808038;
}

.send-button-styles {
    background-color: #07be6e;
    color: white;
    border: 1px solid;
    border-radius: 5px;
    padding: 5px 15px;
    margin-bottom: 8px !important;
}

.btn-blue {
    background-color: #1d82f5;
    border: 1px solid;
    border-radius: 5px;
    padding: 13px;
}

.btn-blue:hover {
    background-color: #0d61c2;
    border: 1px solid;
}

.btn-blue:active {
    background-color: #0d61c2;
    border: 1px solid;
}

.scrollable-list2 {
    padding: 5px;
    /* overflow-y: auto; */
    border: 1px solid rgb(218, 213, 213);
    border-radius: 5px;
}

.CLOSED {
    background-color: #ff6877;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.CHANGEVENDOR {
    background-color: #e89800;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.PRICEAPPROVED {
    background-color: #e89800;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.ms-chat-body {
    overflow: auto;
    height: 82vh;
}


.ms-chat-body::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.ms-chat-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.ms-chat-body::-webkit-scrollbar-thumb {
    background: #88888880;
    border-radius: 10px;
}

/* Handle on hover */
.ms-chat-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}




.ms-panel-body::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.ms-panel-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.ms-panel-body::-webkit-scrollbar-thumb {
    background: #88888880;
    border-radius: 10px;
}

/* Handle on hover */
.ms-panel-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.ms-panel-body {
    overflow: auto;
}

.COUPONGENERATED {
    background-color: #e89800;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.NOREFUND {
    background-color: #ff6877;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.CASHONDELIVERY {
    background-color: #ff6877;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.SUCCESS {
    background-color: #07be6e;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.disabled-form-control {
    background-color: white !important;
}

.description-textarea {
    height: 100px !important;
}

.display-description {
    height: 100px;
}

.cakeType-image img {
    height: 50%;
    width: 50%;
    left: 0;
    right: 0;
    object-fit: contain;
    margin: auto;
}

.bottom-error-message {
    font-weight: 500;
}

tr td.price-align:last-child {
    display: flex;
    justify-content: flex-end;
    width: 55%;
}

tr.price-align-row td {
    position: relative;
}

tr.price-align-row:after {
    border-top: 1px solid #dee2e6;
    position: absolute;
    content: "";
    width: 24%;
    z-index: 99999;
}

tr.price-align-row3 td {
    position: relative;
}

tr.price-align-row3:after {
    border-top: 1px solid #dee2e6;
    position: absolute;
    content: "";
    width: 18%;
    z-index: 99999;
}

tr.price-align-row4 td {
    position: relative;
}

tr.price-align-row4:after {
    border-top: 1px solid #dee2e6;
    position: absolute;
    content: "";
    width: 22%;
    z-index: 99999;
}

tr.VS-price-align-row td {
    position: relative;
}

tr.VS-price-align-row:after {
    border-top: 1px solid #dee2e6;
    position: absolute;
    content: "";
    width: 16%;
    z-index: 99999;
}

tr.VS-price-align-row2 td {
    position: relative;
}

tr.VS-price-align-row2:after {
    border-top: 1px solid #dee2e6;
    position: absolute;
    content: "";
    width: 21%;
    z-index: 99999;
}

/*##########*/

tr td.price-align2:last-child {
    display: flex;
    justify-content: flex-end;
    padding: 25px 0;
    width: 60%;
}

tr td.no-price-align:last-child {
    display: flex;
    justify-content: center;
    padding: 25px 0;
    width: 60%;
}

td.price-align3-row {
    padding: 20px 0;
}

tr td.no-price-align-details:last-child {
    display: flex;
    justify-content: center;
    padding: 13px 0;
    width: 93%;
}

tr.price-align2-row td {
    position: relative;
}

tr.price-align2-row:after {
    border-top: 1px solid #dee2e6;
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 99999;
}

tr.price-align2-row td {
    position: relative;
}

tr.odd.cursor.price-align2-row {
    position: relative;
}

tr.odd.price-align2-row {
    position: relative;
}

.table-price-align td {
    border-top: transparent;
}

.tr-price-align th {
    padding: 12px;
}

.table-price-align td,
.table-price-align th {
    white-space: nowrap;
    vertical-align: middle;
}

.no-records-found {
    padding: 15px 0;
}

.alert-icon {
    font-size: 65px;
    color: #ff6877;
}

.modal-body-scrollar {
    overflow-y: auto;
    max-height: 600px;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden !important;
}

/* ################## */
/* .odd.cursor.price-align3-row td:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    padding: 40px 0;
    width: 60%;
}

tr.price-align3-row td {
    position: relative;
}

tr.odd.cursor.price-align3-row {
    position: relative;
}

tr.odd.cursor.price-align3-row:after {
    position: absolute;
    content: "";
    border-top: 1px solid #dee2e6;
    width: 100%;
    bottom: 0;
    right: 0;
}

tr.price-align3-row:after {
    border-top: 1px solid #dee2e6;
    position: absolute;
    content: "";
    width: 24%;
    z-index: 99999;
}

.table-price-align td {
    border-top: transparent;
}

.tr-price-align th {
    padding: 10px;
} */

/* tr.sales-alignment td:nth-child(4) {
    display: revert;
    text-align: right;
    padding-right: 50px;
} */

td.sales-alignment {
    text-align: right;
    padding-right: 50px;
}

td.miscellaneous-refund {
    text-align: right;
    padding-right: 155px;
}

td.vendor-sales-alignment {
    text-align: right;
    /* padding-right: 100px; */
    padding-right: 45px;
}

td.vendor-details-alignment {
    text-align: right;
    padding-right: 50px;
}

tr.price-alignment td:last-child {
    display: revert;
    text-align: right;
    padding-right: 80px;
}

td.hamper-price-alignment {
    text-align: right;
    padding-right: 80px;
}

td.vendor-hamper-price-alignment {
    text-align: right;
    padding-right: 125px;
}

td.vendor-topper-price-alignment {
    text-align: right;
    padding-right: 175px;
}

td.vendor-statement-alignment {
    text-align: right;
    padding-right: 60px;
}

td.vendor-report-statement-alignment {
    text-align: right;
    padding-right: 60px;
}

td.vendor-share-of-cakey-alignment {
    text-align: right;
    padding-right: 60px;
}

td.vendor-report-share-of-cakey-alignment {
    text-align: right;
    padding-right: 115px;
}

td.vendor-payment-price-alignment {
    text-align: right;
    padding-right: 160px;
}

td.accounts-payment-alignment {
    text-align: right;
    padding-right: 75px;
}

td.accounts-refund-report-price {
    text-align: right;
    padding-right: 33px;
}

td.accounts-refund-report-cancel {
    text-align: right;
    padding-right: 72px;
}

td.accounts-refund-report-refund {
    text-align: right;
    padding-right: 50px;
}

.no-conversation-selected {
    padding: 35% 37% 39% 38%;
}

.conversation-icon {
    font-size: 120px;
    color: #b9b9bd;
    margin-left: 20px;
}

.conversation-class {
    font-size: 16px;
    color: #b9b9bd;
}

.top-nav-icons {
    font-size: 28px;
}

.top-nav-link {
    display: block;
    padding: 0.5rem 0.5rem !important;
}

.ms-chat-no-conversation {
    margin-bottom: 30px;
}

.ms-chat-profile {
    margin-bottom: 10px;
}

.top-nav-chat-icons {
    font-size: 25px;
    margin-bottom: 4px;
}

.chat-badge {
    margin-top: -2px;
}

.dashboard-top {
    margin-top: -15px;
}

.btn-clockin {
    background-color: #445cc8;
    color: white;
    font-size: 16px;
    /* font-weight: 500; */
    border-radius: 5px;
    padding-left: 10px;
}

.btn-clockin:hover {
    background-color: #21317c;
}

.btn-clockout {
    background: darkgreen;
    color: white;
    font-size: 16px;
    /* font-weight: 500; */
    border-radius: 5px;
    padding-left: 10px;
}

.btn-clockout:hover {
    background: #0a9d0a;
}

.box-arrow {
    color: white;
    font-size: 17px;
    margin-bottom: 3px;
    margin-right: 6px;
}

.clock-in-time {
    padding-right: 15px;
    padding-top: 30px;
    color: grey;
    font-size: 14px;
    font-weight: 500;
}

.sort-down-arrow {
    margin-left: -3px;
    margin-bottom: 2px;
}

.sort-up-arrow {
    margin-right: -3px;
    margin-bottom: 2px;
}

.sort-descending {
    color: gray;
}

.sort-descending {
    color: gray;
}

.sort-false {
    color: white;
}

.participants-first-letter {
    display: inline-block;
    font-size: 18px;
    width: 2em;
    height: 2em;
    line-height: 2.0em;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 1em;
    color: white;
}

.person-fill-icon {
    display: inline-block;
    font-size: 18px;
    width: 2em;
    height: 2em;
    line-height: 2.0em;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 1em;
    border: 1px solid #ccc;
    color: white;
    background: #ccc;
    padding: 4px;
}

.participants-first-letter2 {
    display: inline-block;
    font-size: 18px;
    width: 2em;
    height: 2em;
    line-height: 2.0em;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    color: white;
}

.person-fill-icon2 {
    display: inline-block;
    font-size: 18px;
    width: 2em;
    height: 2em;
    line-height: 2.0em;
    text-align: center;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ccc;
    color: white;
    background: #ccc;
    padding: 4px;
}

.send-button-icon {
    font-size: 25px;
}

.enter-notes-border {
    border: 2px solid #e5e5e5;
}

.cake-design-table {
    height: 500px;
}

.vendor-cakes-cake-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* width: 200px; */
    font-size: 18px;
    /* margin-left: 35px; */
}

.vendor-cakes-main-div {
    height: 75%;
    width: 90%;
}

.vendor-cakes-images {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 55%;
}

.vendor-cakes-cake-price {
    margin-left: 95px !important;
}

.REFUNDPENDING {
    background-color: #445cc8;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.Note-message {
    font-size: 16px;
    font-weight: 500;
}

.vendor-report-filter {
    margin-top: -15px;
}

.vendor-report-button {
    padding: 12px !important;
}

.intimation-message {
    font-size: 16px;
    font-weight: 500;
    margin: 0 10px !important;
}

.sales-report-export {
    margin-top: 0px;
    padding: 6px 10px;
}

td.sales-report-alignment {
    text-align: right;
    padding-right: 50px;
}

td.sales-report-alignment-short {
    text-align: right;
    padding-right: 26px;
}

.export-report {
    margin-top: 0px !important;
    padding: 6px 20px;
}

.AVERAGE {
    background-color: #d77636;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.EXCELLENT {
    background-color: #2d703e;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.GOOD {
    background-color: #bee147;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.VERYGOOD {
    background-color: #49bd63;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.NEEDTOIMPROVE {
    background-color: #f5495a;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 6px;
    border-radius: 5px;
}

.responsive-image-width {
    height: 85% !important;
}

.paragraph-header {
    font-size: 14px;
    /* text-transform: uppercase; */
}

.paragraph-line-height {
    line-height: 23px;
}

.policy-breadcrumb {
    background-color: white !important;
}
.bullet-round {
    height: 5px;
    width: 5px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
    margin-bottom: 3px;
    margin-right: 4px;
}

/* tr.sales-alignment td:nth-child(5) {
    display: revert;
    text-align: right;
    padding-right: 50px;
}

tr.sales-alignment td:nth-child(6) {
    display: revert;
    text-align: right;
    padding-right: 50px;
}

tr.sales-alignment td:last-child {
    display: revert;
    text-align: right;
    padding-right: 50px;
} */